<script setup lang="ts">
import {computed} from "vue";
import {services} from "@/config/services";
import {marked} from "marked";
import {useDataStore} from "~/store/data";

const localePath = useLocalePath();
const {state} = useDataStore();
const {t, locale} = useI18n();
const textAbout = computed(() => {
  const block = state.blocks?.records?.find((block: any) => {
    return block.fields.id === `block-about-${locale.value}`
  })
  return block?.fields?.Text ? marked.parse(block.fields.Text) : '';
});
useSeoMeta({
  title: t("seo.index.title"),
  description: t("seo.index.description"),
  ogTitle: t("seo.index.title"),
  ogDescription: t("seo.index.description"),
  titleTemplate: "%s – Cursus OÜ"
});
</script>
<template>
  <section class="container pt-6 pb-10 md:pt-10 lg:py-24 flex flex-col lg:flex-row gap-8 lg:gap-16 items-center flex-1">
    <div class="flex-1 text-center lg:text-left">
      <h1 class="font-bold text-[48px] lg:text-[52px] xl:text-[72px] leading-[1em] mb-5 w-[90%] max-w-[340px] md:max-w-full md:w-full mx-auto">
        {{ t("section.hero.title") }}
        <span
            class="inline-flex translate-y-1 lg:translate-y-2">🇪🇪</span></h1>
    </div>
    <div class="lg:w-[440px] xl:w-[560px]">
      <div class="">
        <nuxt-img src="/img/hero.jpg" format="webp" quality="90" sizes="100vw,sm:50vw,md:75vw,lg:440px,xl:560px"
                  class="rounded-xl overflow-hidden" :alt="t('section.hero.title')" width="800" height="600"/>
      </div>
    </div>
  </section>

  <section class="py-10 lg:py-16 bg-gray-50">
    <div class="container">
      <h2 class="text-left font-bold text-3xl lg:text-4xl mb-8">{{ t("label.services") }}</h2>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-10">
        <nuxt-link v-for="(service, index) in services" :key="index" :to="localePath(`/services/${service.slug}`)"
                   class="relative rounded-xl  overflow-hidden group">
          <nuxt-img :src="`/img/${service.cover}`" format="webp" quality="90"
                    sizes="100vw,sm:50vw,md:75vw,lg:440px,xl:560px"
                    class="overflow-hidden transition-all group-hover:scale-[1.15] duration-500 w-full h-full object-cover"
                    :alt="t(`seo.service-${service.slug}.description`)"/>
          <h3 class="absolute bottom-0 left-0 m-4 md:m-6 bg-white/80 backdrop-blur-sm px-3 py-1 rounded-[8px] text-lg">
            {{ t(service.title) }}</h3>
        </nuxt-link>
      </div>
    </div>
  </section>

  <section class="container py-10 lg:py-16">
    <h2 class="text-left font-bold text-3xl lg:text-4xl mb-8">{{ t("label.about") }}</h2>
    <div class="lg:w-1/2">
      <div :class="content.text" v-if="state.loading">
        <p class="animate-pulse h-5 bg-gray-100 rounded-[6px]" v-for="index in 6" :key="index"
           :style="`width: ${Math.floor(Math.random() * 5) + 5}0%`"/>
      </div>
      <div v-else v-html="textAbout" :class="content.text"/>
    </div>
  </section>
</template>

<style module="content">
.text {
  @apply text-base;
  & > ul,
  & > p {
    @apply mb-4;
  }

  & > ul {
    @apply list-disc pl-6;
  }
}
</style>